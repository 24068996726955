import axios, { AxiosResponse } from "axios";
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CompanyInterface, GetCategoriesInteface, ProductInterface, BannerInterface, CouponProductInterface, CouponRating } from '../interfaces/product.interface';
import { FranquiciaInterface, IPopup } from '../interfaces/getClubFibex.interface';
import { LocalStorageService } from './local-storage.service';
import { IUserSae, UserAuthenticationService } from './user-authentication.service';
import { UserInterface } from '../interfaces/userInfo';
import { BehaviorSubject, Observable } from 'rxjs';
import { QrInterface } from '../interfaces/club-qr.interface';
import { LocationDataInterface } from '../interfaces/location-nominatim.interface';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { dbFull, dbFullDataBase } from "../lib/dbfullv2";
import { SeguridadDatos } from "./bscript.service";


interface LandingBanner{id:number;imgDesktop:string;imgMobile:string;type:string;category:string;status:number;createdAt:Date;updatedAt:Date}
type ResFav = { detail: string; status: boolean }

@Injectable({
    providedIn: 'root'
})
export class ClubFibexFeedingService {

    private ShowInfoTable: any = ""
    private Excluido: any = ["createdAt", "updatedAt"];
    private user: IUserSae | null;
    public favProducts: BehaviorSubject<ProductInterface[]> = new BehaviorSubject<ProductInterface[]>([]);
    public goToMainMenuSubject: BehaviorSubject<void> = new BehaviorSubject<void>(null);
    public popupIndexCount:number = 0;

    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService,
        private AuthService: UserAuthenticationService,
        private router: Router,
        private enc: SeguridadDatos
    ) {
        this.user = AuthService.getUser()
    }

    ngOnInit(): void {
        //this.ShowInfo();
    }

    async GetCategories(): Promise<GetCategoriesInteface[] | null> {
        try {
            const result: AxiosResponse<GetCategoriesInteface[]> = await axios.get<GetCategoriesInteface[]>(`${environment.UrlFull}api/v1/find-any-info/${environment.clubFibexBD}/cb_EmpCategoria/Status/1`)
            return result.data;
        } catch (error) {
            return null
        }
    }

    async GetCompanies(): Promise<CompanyInterface[]> {
        try {
            const result: AxiosResponse<CompanyInterface[]> = await axios.get<CompanyInterface[]>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    // "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.CompanyApp WHERE idEnSAE = "${idFranquiciaUser}";`
                    "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.CompanyApp`
                }
            })
            //   console.log('result',result)
            return result.data;
        } catch (error) {
            return null
        }
    }

    /**
     * Permite obtener la cantidad total de compañias
     */
    async GetCountCompanies(): Promise<number> {
        try {
            const result = await axios.get<[{count: number}]>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    // "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.CompanyApp WHERE idEnSAE = "${idFranquiciaUser}";`
                    "x-data-query": `SELECT count(*) as count FROM ${environment.clubFibexBD}.CompanyApp`
                }
            });
            //   console.log('result',result)
            return result.data[0].count || 0;
        } catch (error) {
            return 0;
        }
    }

    /**
     * Permite obtener de manera paginada las compañias o marcas aliadas
     * @param from Indice al buscar
     * @param limit Cantidad de elementos a obtener a partir del indice establecido
     * @returns Lista de datos de las compañias
     */
    async GetCompaniesSliced(from: number, limit: number): Promise<CompanyInterface[]> {
        const offset = (from-1)*limit;
        try {
            const result: AxiosResponse<CompanyInterface[]> = await axios.get<CompanyInterface[]>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    // "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.CompanyApp WHERE idEnSAE = "${idFranquiciaUser}";`
                    "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.CompanyApp limit ${limit} offset ${offset}`
                }
            })
            //   console.log('result',result)
            return result.data;
        } catch (error) {
            return null
        }
    }

    async GetCompany(id_company: string): Promise<CompanyInterface> {
        try {
            const result: AxiosResponse<CompanyInterface[]> = await axios.get<CompanyInterface[]>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.CompanyApp WHERE id = "${id_company}"`
                }
            })
            //   console.log(result.data[0])
            return result.data[0];
        } catch (error) {
            return null
        }
    }
    async GetCompaniesSelect(limit: number, offset: number,arrayCitiesId?: string[],arrayCategories?: string[],searchData?: string, qAreScratchWin?: number): Promise<any[]> {
        try {
            let currentOff= (offset-1)*limit;
            const search: string = searchData ? searchData : "";
            const citiesId = arrayCitiesId && arrayCitiesId.length>0 ?  `"${arrayCitiesId.map(idCitie=>`${idCitie}`).join(",")}"` : `""`
            const categories =  arrayCategories && arrayCategories.length>0 ?  `"${arrayCategories.map(idCategory=>`${idCategory}`).join(",")}"` : `""`
            const numberSAW = qAreScratchWin === 1 ?`${qAreScratchWin}` : 'NULL';
            const result: AxiosResponse<any[]> = await axios.get<any[]>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `CALL companiesByCitiesV2(${currentOff},${limit},${citiesId},${categories},"${search}",${numberSAW})`
                }
            })
            return Object.values(result.data[0]);
        } catch (error) {
            console.error(error);
            
            return null
        }
    }
    async SearchCompaniesSelect( name:any): Promise<any[]> {
        try {
            const result: AxiosResponse<any[]> = await axios.get<any[]>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `SELECT id,name FROM ${environment.clubFibexBD}.CompanyApp WHERE name LIKE("%${name}%")`
                }
            })
            //   console.log(result.data[0])
            return result.data;
        } catch (error) {
            return null
        }
    }
    async SearchCompaniesById( ids: string): Promise<any[]> {
        try {
            console.log(ids);
            const result: AxiosResponse<any[]> = await axios.get<any[]>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `SELECT ca.id,ca.name FROM ${environment.clubFibexBD}.CompanyApp ca WHERE ca.id IN(${ids})`
                }
            })
            //   console.log(result.data[0])
            return result.data;
        } catch (error) {
            return null
        }
    }
    async GetProducts(idFranquiciaUser: string, nro_contrato: string): Promise<ProductInterface[]> {
        // console.log('idFranquiciaUser', idFranquiciaUser)
        // console.log('contrato', nro_contrato)
        try {
            console.log('headers', {
                "x-data-query": `CALL ProudctAppv2('${idFranquiciaUser}','${nro_contrato}');`
            })
            const result: AxiosResponse<ProductInterface> = await axios.get<ProductInterface>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `CALL ProudctAppv2('${idFranquiciaUser}','${nro_contrato}');`
                }
            })
            console.log(`CALL ProudctAppv2('${idFranquiciaUser}','${nro_contrato}');`)
            //   console.log('result.data', result.data)
            return Object.values(result.data[0]);

        } catch (error) {
            console.error(error)
            return null
        }
    }

    async GetFranqByCurrentLocation(): Promise<FranquiciaInterface | null> {
        try {
            const currentLocation: LocationDataInterface = await this.GetLocation()
            // console.log('currentLocation', currentLocation)

            if (currentLocation && currentLocation.address.city) {
                const userCity: string = `FIBEX ${currentLocation.address.city.toUpperCase()}`;
                const result: AxiosResponse<FranquiciaInterface> = await axios.get<FranquiciaInterface>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.cb_Franquicia WHERE Nombre = "${userCity}";`
                    }
                })
                return result.data;
            }
            return null
        } catch (error) {
            console.error(error)
            return null
        }
    }

    async GetBanners(idFranquiciaUser?: string): Promise<BannerInterface[]> {
        try {
            const result: AxiosResponse<BannerInterface[]> = await axios.get<BannerInterface[]>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.cb_BannerPublic`
                }
            })
            return result.data;
        } catch (error) {
            return null
        }
    }

    async GetFranquicia(idFranquiciaUser: string): Promise<FranquiciaInterface> {
        try {
            const result: AxiosResponse<FranquiciaInterface> = await axios.get<FranquiciaInterface>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.cb_Franquicia WHERE idFranquicia = "${idFranquiciaUser}";`
                }
            })
            // const franqName: unknown = result.data
            // console.log("franqName",franqName.Nombre.replace('FIBEX ', ''))
            // console.log(result.data)
            return result.data;
        } catch (error) {
            return null
        }
    }
    async GetAllFranquicias(): Promise<FranquiciaInterface[]> {
        try {
            const result: AxiosResponse<FranquiciaInterface[]> = await axios.get<FranquiciaInterface[]>(`${environment.UrlFull}api/v1/find-any-info/${environment.clubFibexBD}/cb_Franquicia/Status/1`)
            // console.log('all franquicias', result.data)
            return result.data;
        } catch (error) {
            return null
        }
    }

    async GetProductBranchOffices(productID: string, franchiseID: string,idPromo:string): Promise<any> {
        try {
            console.log(productID)
            console.log(franchiseID)
            const result: AxiosResponse<any> = await axios.get<unknown>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `CALL ProductoSucursales('${productID}','${franchiseID}','${idPromo}');`
                }
            });
            return Object.values(result.data[0]);
        } catch (error) {
            console.error(error);

            return null
        }
    }

    async GetAllData(table: string) {
        return new Promise(async (resolve, reject) => {
            axios.get(`${environment.UrlFull}api/v1/find-all-info/${environment.bd}/${table}`)
                .then((resp: AxiosResponse) => resolve(resp.data))
                .catch((error: Error) => reject(error));
        })
    }

    async GetAllDataCampo(table: string, campo: string, valor: any) {
        return new Promise(async (resolve, reject) => {
            axios.get(`${environment.UrlFull}api/v1/find-any-info/${environment.bd}/${table}/${campo}/${valor}`)
                .then((resp: any) => resolve(resp.data))
                .catch((error: any) => reject(error));
        })
    }

    async CreateData(table: string, idkey: string, body: any) {
        return new Promise(async (resolve, reject) => {
            let config: any = {
                method: 'post',
                url: `${environment.UrlFull}api/v1/create-info/${environment.bd}/${table}`,
                headers: {
                    'x-keys-to-add-id': `["${idkey}"]`,
                    'x-keys-of-arrays': '[]',
                    'x-relations': 'false',
                    'Content-Type': 'application/json'
                },
                data: body
            };
            axios.request(config)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        })
    }

    async UpdateData(table: string, campoSearch: string, body: any) {
        return new Promise(async (resolve, reject) => {
            let config: any = {
                method: 'put',
                url: `${environment.UrlFull}api/v1/update-info/${environment.bd}/${table}/${campoSearch}`,
                headers: {
                    'x-multiple-update': 'false',
                    'x-elements-obj': '[]',
                    'x-attr-duplicate': '[]',
                    'campo': '',
                    'Content-Type': 'application/json'
                },
                data: body
            };
            axios.request(config)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        })
    }

    async ShowInfo() {
        return new Promise(async (resolve, reject) => {
            if (this.ShowInfoTable && this.ShowInfoTable != "") {
                resolve(this.ShowInfoTable)
            } else {
                let config: any = {
                    method: 'get',
                    url: `${environment.UrlFull}api/v1/show-all-info/${environment.bd}`,
                };
                axios.request(config)
                    .then((response) => { resolve(response.data); this.ShowInfoTable = response.data })
                    .catch((error) => reject(error));
            }
        })
    }

    async CamposTableReturn(table: string) {
        return new Promise(async (resolve, reject) => {
            if (table && table != "") {
                if (this.ShowInfoTable && this.ShowInfoTable != "") {
                    let info = this.ShowInfoTable.model.filter((datadb: any) => (datadb.tableName === table))
                    if (info && info.length > 0) {
                        info = info[0].attr.filter((camposexluido: any) => (camposexluido != "createdAt" && camposexluido != "updatedAt"));
                        resolve(info);
                    } else
                        resolve(info);
                } else {
                    this.ShowInfo()
                        .then((resp: any) => {
                            this.ShowInfoTable = resp;
                            let info = this.ShowInfoTable.model.filter((datadb: any) => (datadb.tableName === table))
                            if (info && info.length > 0) {
                                console.log("info");
                                console.log(info[0].attr);
                                info = info[0].attr.filter((camposexluido: any) => (camposexluido != "createdAt" && camposexluido != "updatedAt"))
                                resolve(info)
                            } else
                                resolve(info);
                        })
                        .catch((error: any) => { reject({ error: error }) })
                }
            } else {
                reject({ error: "Error debes poner un nombre de tabla" })
            }
        })
    }

    Test() {
        /*Test1 por campos
        this._dbFullService.GetAllDataCampo('cb_EmpCategoria','nombre','Comida')
        .then((resp)=>{
          console.log("respondio");
          console.log(resp);
        })
        .catch((error:any)=>{
          console.log(error);
        })*/

        /*
        Test2
        let Data ={
          idEmpConv:"100",
          idFranquicia:"GG5HP",
          Nombre: "Damasco",
          Status:true,
          ImgURL: "https://usatoday.com"
        }
     
        this._dbFullService.CreateData('cb_EmpresaSucursal','idSucursal',Data)
        .then((resp)=>{
          console.log("respondio");
          console.log(resp);
        })
        .catch((error:any)=>{
          console.log(error);
        })*/

        /* 
        Test3
        let Data ={
          idEmpCat:"123458",
          nombre: "Comida",
          icon:"fa fa-burguer",
          img: "http://scribd.com2"
        }
     
        this._dbFullService.UpdateData('cb_EmpCategoria','idEmpCat',Data)
        .then((resp)=>{
          console.log("respondio");
          console.log(resp);
        })
        .catch((error:any)=>{
          console.log(error);
        })
        */
    }

    public async handleProductFav(product: ProductInterface): Promise<void> {
        try {
            const user = this.AuthService.getUser();
            // const storageData = this.localStorageService.get('cedulas');
            const { nro_contrato } = user;
           // const { idProducto } = product;
            const { id } = product;
            const body = {
              idAbonado: nro_contrato,
              idProducto: id,
            };
            
            product.fav = !product.fav;
            const result: AxiosResponse<ResFav> = await axios.post<ResFav>(`${environment.apiTest}/${environment.favMethod}`, body)
             console.log('result', result)
            // if (result && result.status) {

            //     /*
            //     !!Almacenamiento en el local Storage
            //     let favProductsStorage: ProductInterface[] = this.localStorageService.get('fav_products') || []
            //     console.log("favProductsStorage", favProductsStorage);
                 
            //      if (product.fav) {//!Elimino item del localStorage
        
            //       favProductsStorage.push(product)
            //       console.log('Item Guardado', favProductsStorage)
            //       this.localStorageService.set('fav_products', favProductsStorage)
        
            //     } else {//Lo agrego al local storage
            //       let index = favProductsStorage.findIndex((productStorage:ProductInterface) => product.id === productStorage.id);
            //       favProductsStorage.splice(index,index+1)
            //       this.localStorageService.set('fav_products', favProductsStorage)
        
            //     }
                  
            //       //!Actualizo el producto de la base de datos
            //       if (favProductsStorage && favProductsStorage.length) {
            //         favProductsStorage.map(async productStorage => {
            //           const ProductDB = await this.filterProductById(productStorage.id, nro_contrato)
            //           console.log("ProductDB;",ProductDB);
            //           productStorage = ProductDB;
            //         })
            //       }
            //       console.log("favProductsStorage", favProductsStorage);
            //       */
            // }
            // console.log("FavResult", result.data)
        } catch (error) {
            console.error(error);
        }
    }

    public async filterProductById(productId: string, nro_contrato: string): Promise<ProductInterface | null> {
        try {
            let products: ProductInterface[] = await this.GetProducts(this.user.id_franq, nro_contrato)
            return products.find(product => productId === product.id)
        } catch (error) {
            return null
        }
    }

    async GetCoupons(idAbonado: string): Promise<CouponProductInterface[] | null> {
        // console.log("idAbonado", idAbonado);

        try {
            const result: AxiosResponse<CouponProductInterface> = await axios.get<CouponProductInterface>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `CALL Cupones("${idAbonado}");`
                    // "x-data-query": `CALL Cupones("C0824");`
                }
            })
            return Object.values(result.data[0]);
        } catch (error) {
            return null
        }
    }

    async postRating(rating: any){
        try {


            const body = rating;

            const result = await axios.post<any>(`${environment.apiTest}` + '/ReportCuponsGenerated' , body);
            
            return result.data;

        } catch (error) {
            return null
        }
    }

    async ProductoSucursales(productId: string): Promise<any[] | null> {
        console.log("productId", productId);

        try {
            const result: AxiosResponse<CouponProductInterface> = await axios.get<CouponProductInterface>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `CALL ProductoSucursales("${productId}");`
                }
            })
            return Object.values(result.data[0]);
        } catch (error) {
            return null
        }
    }

    async GetFavProducts(): Promise<Observable<ProductInterface[] | null>> {
        try {
            // console.log("GetFavProducts");
            let productsFav: ProductInterface[] = await this.GetProducts(this.user.id_franq, this.user.nro_contrato)
            productsFav = productsFav.filter(product => product.fav)
            this.favProducts.next(productsFav)
            return this.favProducts.asObservable();
        } catch (error) {
            return null;
        }
    }

    async GetQR(product: ProductInterface, branchOfficeID: string = 'asdsd5',amountCredit?: number): Promise<QrInterface> {
        try {
            const { id, company, idAbonado, TipoCanjeo, CodigoFijo } = product

            const body: any = {
                idEmpConv: company,
                // idSucursal: 'asdsd5',
                idSucursal: branchOfficeID,
                idAbonado: idAbonado,
                idPromo: id,
                TipoCanjeo: TipoCanjeo,
                CodigoFijo: CodigoFijo
            }
            if(amountCredit && amountCredit>0) body.monto_gc = amountCredit;
            console.log(body);
            
             const result: AxiosResponse<QrInterface> = await axios.post<QrInterface>(`${environment.apiTest}/${environment.qrMethod}`, body)

            return result.data
        } catch (error) {
            console.error(error);
            return null

        }
    }

    public goToMainMenuEvent(): Observable<void> {
        // console.log('next')
        this.goToMainMenuSubject.next()
        return this.goToMainMenuSubject.asObservable()
    }

    async GetLocation(): Promise<LocationDataInterface | null> {//
        if (navigator.geolocation) { //check if geolocation is available
            return new Promise<LocationDataInterface>((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(async (position) => {
                    const result: AxiosResponse<LocationDataInterface> = await axios.get<LocationDataInterface>(`https://nominatim.openstreetmap.org/reverse?lat=${position.coords.latitude}&lon=${position.coords.longitude}&format=json`)
                    // console.log('location', result.data)
                    resolve(result.data)
                }, (error) => reject(error))
            })
        }
    }

    async getAdvertisingBanners() {
        try {
            const result: AxiosResponse<any> = await axios.get<any>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.cb_AdvertisingBanner WHERE status = 1;`
                }
            })
            return result.data;

        } catch (error) {
            return null
        }
    }

    async getMarksBanners() {
        try {
            const result: AxiosResponse<any> = await axios.get<any>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.cb_BannerPublic WHERE status = 1;`
                }
            })
            return result.data;

        } catch (error) {
            return null
        }
    }

    async generateQRBill({ NombreProducto, description, idAbonado, price, Codigo, Cupon }) {
        const current = new Date();
        const body = {
            date: current.toLocaleDateString('en-GB'),
            time: current.toLocaleTimeString('en-US'),
            promoTitle: NombreProducto,
            subscriber: idAbonado,
            price,
            promoDescription: description,
            qrUrl: Cupon,
            qrCode: Codigo,
        }
        try {
            const result = await axios.post('https://apitest5.thomas-talk.me/generate-qr-bill', body)
            // const result = await axios.post('http://localhost:8200/generate-qr-bill', body)
            return result.data

        } catch (error) {
            console.error(error)
        }
    }

    public async getPopUpClubFibex(): Promise<string> {
        try {
            let popUpVideos: AxiosResponse<IPopup[]> = await axios.get<any[]>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.cb_LandingBanner WHERE type = 'popUp' AND status = 1;`
                }
            })
            this.addPopUpVideo(popUpVideos.data)
            return popUpVideos.data[new Date().getDate()].imgDesktop

        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Agrega más videos popup si la longitud es menor a 31
     */
    public addPopUpVideo = (popupVideos:IPopup[]): void => {
        if (popupVideos.length <= 31 && this.popupIndexCount <= 31) {
            popupVideos.push(popupVideos[this.popupIndexCount])
            this.popupIndexCount++;
            this.addPopUpVideo(popupVideos)
        }
    }

    async getRecommendAndWinAd() {
        try {
            console.log(`SELECT * FROM ${environment.clubFibexBD}.cb_LandingBanner WHERE type = 'recAndWin' AND status = 1;`)
            console.log(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`)
            
            const response: AxiosResponse<any[]> = await axios.get<any[]>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.cb_LandingBanner WHERE type = 'recAndWin' AND status = 1;`
                }
            })
            return response.data;

        } catch (error) {
            return null
        }
    }

    async getRecommendAndWinGif() {
        try {
            const response: AxiosResponse<any[]> = await axios.get<any[]>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.cb_LandingBanner WHERE type = 'recAndWinGif' AND status = 1;`
                }
            })
            return response.data;

        } catch (error) {
            return null
        }
    }
     //METODOS CONSULTAS PAGINADOS
    //STORED PROCEDURES
    public async getCouponsPaginated(idAbonado: string,currentPage: number,limitPage: number): Promise<CouponProductInterface[] | null>{
        try {
            let totalPages = 0;
            let offset = (currentPage-1)*limitPage;
            const result: AxiosResponse<CouponProductInterface> = await axios.get<CouponProductInterface>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `CALL Cupones_pag("${idAbonado}",${offset},${limitPage});`
                    // "x-data-query": `CALL Cupones("C0824");`
                }
            })
            console.log('',"RESULTADO: ",result);
            return Object.values(result.data[0]);
        } catch (error) {
            return null
        }
    } 
    public async getProductsPaginated(idFranquiciaUser: string, nro_contrato: string,currentPage: number,limitPage: number,categories?: string,companies?: string,pricing?: string[], typeSearch?: number, textSearch?: string,qAreScratchWin?: number): Promise<ProductInterface[]>{
        try {
            console.log(qAreScratchWin);
            
            let totalPages = 0;
            let offset = currentPage;
            const data = categories ? `'${categories}'` : 'NULL';
            const dataCompany = companies ? `'${companies}'` : 'NULL';
            const dataPricing = pricing && pricing.length == 2 ? `${pricing[0]},${pricing[1]}` : `NULL,NULL`
            const numberTypeSearch = `${typeSearch || 0}`;
            const searchType = `"${textSearch || ""}"`;
            const numberSAW = qAreScratchWin === 1 ?`${qAreScratchWin}` : 'NULL';
            console.log(numberSAW);
            
            const    headers = {
                "x-data-query": `CALL getProductsFilteredV3('${idFranquiciaUser}','${nro_contrato}',${numberTypeSearch},${numberSAW},${searchType},${offset},${limitPage},${data},${dataCompany},${dataPricing});`
            }
            console.log(headers);
             const result: AxiosResponse<ProductInterface> = await axios.get<ProductInterface>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `CALL getProductsFilteredV3('${idFranquiciaUser}','${nro_contrato}',${numberTypeSearch},${numberSAW},${searchType},${offset},${limitPage},${data},${dataCompany},${dataPricing});`
                }
            })
            return Object.values(result.data[0]);

        } catch (error) {
            console.error(error)
            return null
        }
    } 
    public async getProductsSucPaginated(productId: string,currentPage: number,limitPage: number,  ){
        try {
            let totalPages = 0;
            let offset = (currentPage-1)*limitPage;
            console.log("productoSsucursales_pag")
            console.log("productId")
            console.log(productId)
            console.log("offset")
            console.log(offset)
            console.log("limitPage")
            console.log(limitPage)
            
            const result: AxiosResponse<CouponProductInterface> = await axios.get<CouponProductInterface>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `CALL productoSsucursales_pag('${productId}',${offset},${limitPage});`
                  
                }
            })
            return Object.values(result.data[0]);
        } catch (error) {
            return null
        }
    } 
    //VISTAS Y TABLAS
    async GetAllDataPaginated(table: string,currentPage: number,limitPage: number,dataCampo ?: {campo: string, valor: any}[]) {
        return new Promise(async (resolve, reject) => {
            let conditionalsConcatenated = "";
            let offset = (currentPage-1)*limitPage;
            if(dataCampo){
            conditionalsConcatenated+="WHERE ";
            conditionalsConcatenated+= dataCampo.map(obj=>{
                let valor = typeof(obj.valor)=='string'? `'${obj.valor}'` :  `${obj.valor}`;
                return `${obj.campo} =  ${valor}`}
            ).join("AND");
            }
         try {
            const result: AxiosResponse<any> = await axios.get<any>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.${table} ${conditionalsConcatenated} LIMIT ${limitPage} OFFSET ${offset}`
                } 
            
            })
            resolve(  result.data);
        } catch (error)  {
            return null
        }
        });
    }
    async getCompaniesPaginated(currentPage:number,limitPage:number): Promise<CompanyInterface[]> {
        try {
            const result: CompanyInterface[] = await this.GetAllDataPaginated("CompanyApp",currentPage,limitPage) as CompanyInterface[];
            console.log('','result',result) 
            return result;
        } catch (error) {
            return null
        }
    }
    async getBannersPaginated(): Promise<BannerInterface[]> {
       {
            try {
                const result: BannerInterface[] =await  this.GetAllDataPaginated("cb_BannerPublic",1,5,[{campo:"Status",valor:"1"}]) as BannerInterface[];
                return result;
            } catch (error) {
              
            }
        }
    }
    async searchProductsByName(idAbonado: string,dataSearch: string):Promise<ProductInterface[]>{

    try {
      //  alert("enviando: "+dataSearch);
      const result: AxiosResponse<ProductInterface> = await axios.get<ProductInterface>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
        headers: {
            "x-data-query": `CALL searchProducts("${idAbonado}","${dataSearch}")`
          
        }
    })
    return Object.values(result.data[0]);   
    
    
    
    } catch (error) {
        console.error(error);
        return [];
}        
    }     
 
    async getAdvertisingBannersPaginated(): Promise<any[]> {
        try {
            
            const result: any[] = await  this.GetAllDataPaginated("cb_AdvertisingBanner",1,5,[{campo:"Status",valor:"1"}]) as any[];
            return result;    
        }
            
        catch (error) {
            return null
        }
    
    }
    
//7
    async getMarksBannersPaginated(): Promise<any[]>  {
        //cb_BannerPublic
        try {
            const result: any[] = await  this.GetAllDataPaginated("cb_BannerPublic",1,5,[{campo:"Status",valor:"1"}]) as any[];
            return result;    
        }    
        catch (error) {
            return null
        }
    }
    async gettPopUpsOM(): Promise<any>{
        try {
            const result: AxiosResponse<any> = await axios.get<any>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.cb_LandingBanner WHERE type='popUpOM' AND status = 1;`
                }
            })
            return result.data;
        }       
        catch (error) {
            return null
        }
    }

    async gettVideoOM(): Promise<any>{
        try {
            const result: AxiosResponse<any> = await axios.get<any>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.cb_LandingBanner WHERE type='videoBanner' AND status = 1;`
                }
            })
            return result.data;
        }       
        catch (error) {
            return null
        }
    }
    public processQREmpresa = async (QRcode: string, idEmpConv: string, CodigoEmpresa: string): Promise<any> => {
        try {
            const body = {
                QRcode,
                idEmpConv,
                CodigoEmpresa
            };
            console.log('body', body);
            const result: AxiosResponse = await axios.post(`${environment.apiTest}/ExchangeTienda`, body);
            console.log('RESULT SCAN >>>', result.data);
            
            if (result.data["message"] && result.data["message"] === "QR Aliado Invalido") {
                throw new Error(result.data["message"]);
            }
            if (!result.data['status']) {
                throw new Error(result.data['error']);
            }
    
            return result.data;
        } catch (error) {
            console.error(error);
            return Promise.reject({ error: error.name });
        }
    };
    
    public getCouponByID = async (idCupon: string): Promise<void> => {
        try {
            const result: AxiosResponse<any> = await axios.get<any>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.cb_ControlCupon ccc WHERE ccc.idControlCupon="${idCupon}"`
                }
            })
            return result.data[0];
        }       
        catch (error) {
            return null
        }
    }
    public getDaysCoupon = async (QRcode): Promise<any>=>{
        try {
            const  dbCon: dbFullDataBase = new dbFullDataBase(environment.clubFibexBD);
            const response: any[] = await dbCon.GET_ANY_QUERY({query: `SELECT ccc.FechaGenerado, cpp.FechaLimite FROM cb_ControlCupon ccc 
            INNER JOIN cb_PromoProductos cpp ON(ccc.idPromo = cpp.idPromo)
            WHERE ccc.QR ="${QRcode}" AND ccc.Status = 1 AND ccc.Canjeado =0`});
            if(Array.isArray(response) && response.length>0){
                const data = response[0];
                return Promise.resolve({status: true, FechaGenerado: data.FechaGenerado, FechaLimite: data.FechaLimite})
            }
            else{
                return Promise.reject({status: false,message: "El cupon no se encuentra dentro de la promocion,  esta inactivo  o ha sido canjeado"});
            }
        }       
        catch (error) {
            console.error(error);
            return Promise.reject(null);
        }
    }
    public getRankingPromos = async ():Promise<any>=>{
        try {
            const result: AxiosResponse<any> = await axios.get<any>(`${environment.apiTest}/getrankingcoupons`);

            const responseData = result.data;
            console.log(responseData.data);
            if(responseData.status && Array.isArray(responseData.data) && responseData.data.length>0){ 
                return Promise.resolve(responseData.data);
            }
            throw new Error("Error en obtener el ranking")
        } catch (error) {
        console.error(error);
        return Promise.reject(error);   
        }
      
    }
    public getRecentPromos = async ():Promise<any>=>{
        try {
            const result: AxiosResponse<any> = await axios.get<any>(`${environment.apiTest}/getrecentpromos`);

            const responseData = result.data;
            console.log(responseData.data);
            if(responseData.status && Array.isArray(responseData.data) && responseData.data.length>0){
                return Promise.resolve(responseData.data);
            }
            throw new Error("Error en obtener el ranking")
        } catch (error) {
        console.error(error);
        return Promise.reject(error);   
        }
      
    } 
    public getFeaturedPromos = async (idAbonado:string):Promise<ProductInterface[]>=>{
        try {
            const result: AxiosResponse<any> = await axios.get<ProductInterface[]>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `CALL promosFeatured('${idAbonado}');`
                }
            })
            console.log(result.data);
            
            return Object.values(result.data[0]);
                } catch (error) {
        console.error(error);
        return Promise.reject(error);   
        }
      
    }
    public getFeaturedAllies = async ():Promise<any>=>{
        try {
            const result: AxiosResponse<any> = await axios.get<any>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.CompaniesFeatured`
                }
            })
            return result.data;
        } catch (error) {
        console.error(error);
        return Promise.reject(error);   
        }
      
    }
    public getSucursales = async ():Promise<any>=>{
        const result: AxiosResponse<any> = await axios.get<any>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
            headers: {
                "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.AviableCities`
            }
        })
        return result.data;       
    }
    public async getContactDateByEnterprise(idEmpConv: any){
        console.log(idEmpConv);

        const result: AxiosResponse<any> = await axios.get<any>(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
            headers: {
                "x-data-query": `SELECT ccp.Telefono,ccp.Contacto FROM cb_ContactoPrincipal ccp WHERE ccp.idEmpConv = '${idEmpConv}'`
            }
        })
        console.log(result.data);
        return result.data[0];     
    }
    // exchangeCouponProcess(QrObj: { QRcode: string } | { idControlCupon: string }): Observable<any> {
    //     // if (!QrObj?.QRcode) {
    //     //   return throwError('Dato inválido');
    //     // }
    //     // const url = ${this.apiUrl}/ExchangeCupon;
    //     const headers = this.getHeaders();
    //     const options: { headers: HttpHeaders; observe: 'response' } = {
    //       headers,
    //       observe: 'response'
    //     };
    
    //     return this.http.post(url, QrObj, options)
    //       .pipe(
    //         map((response: HttpResponse<any>) => {
    //           if (response.status !== 200) {
    //             throw new Error('La llamada al servidor no fue exitosa');
    //           }
    //           return response.body;
    //         }),
    //         catchError((error: any) => {
    //           let errorMessage = 'Plataforma no disponible, intente más tarde.';
    
    //           if (error.error && error.error.message) {
    //             errorMessage = error.error.message;
    //           }
    //           return throwError(errorMessage);
    //         })
    //       );
    //   }
}