import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

    // Método para guardar datos en el Local Storage
    public set(key: string, value: any): void {
      localStorage.setItem(key, JSON.stringify(value));
    }
  
    // Método para obtener datos del Local Storage
    public get(key: string): any {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    }
  
    // Método para guardar datos con tiempo de expiración
    public setWithExpiry(key: string, value: any, ttl: number): void {
      const now = new Date().getTime();
      const item = {
        value: value,
        expiry: now + ttl,
      };
      this.set(key, item);
    }
  
    // Método para obtener datos y verificar si han expirado
    public getWithExpiry(key: string): any {
      const itemStr = this.get(key);
  
      if (!itemStr) {
        return { status: false, message: 'Item not found in cache', data: null };
      }
  
      const item = itemStr;
      const now = new Date().getTime();
  
      if (now > item.expiry) {
        localStorage.removeItem(key);
        return { status: false, message: 'Cache expired', data: null };
      }
  
      return { status: true, message: 'Cache valid', data: item.value };
    }
  public remove(item: string) {
    try {
      localStorage.removeItem(item);
    }
    catch(err) {
      console.error(err);
    }
  }

  public removeAll() {
    const id = JSON.parse(localStorage.getItem('Unique_IdDevice'));
    localStorage.clear();
    localStorage.setItem('Unique_IdDevice', JSON.stringify(id))
  }

}
