import * as i0 from "@angular/core";
export class LettersService {
    /*
    ==============================================
      Servicio para modificar textos al rededor de la app
    ==============================================
    */
    constructor() { }
    toUpperAndLowerCase(text) {
        return text.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
    }
    shortNamesFunc(name) {
        let shortNames, spaceIndex = name.indexOf(' ');
        spaceIndex = name.indexOf(' ', spaceIndex + 1);
        shortNames = name.slice(0, spaceIndex > 0 ? spaceIndex : undefined);
        return shortNames;
    }
    getFormatNames(names) {
        return this.toUpperAndLowerCase(this.shortNamesFunc(names));
    }
}
LettersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LettersService_Factory() { return new LettersService(); }, token: LettersService, providedIn: "root" });
