import { Component, Input, OnInit } from '@angular/core';
import { buttonInterface } from '../../../../interfaces/button-interface';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-modal-message',
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.scss'],
})
export class ModalMessageComponent {

  constructor(private popOver: PopoverController) { 
  }


  @Input() type: 'success' | 'error' = 'success';
  @Input() title: string = 'Awesome!';
  @Input() message: string = 'Your invitation has been sent successfully!';
  @Input() actionText: string = 'Got it!';
  @Input() buttonList?: buttonInterface[] = [{titleButton: 'Cerrar', onClick: () => this.popOver.dismiss()}];
  get iconName() {
    return this.type === 'success' ? 'checkmark-circle' : 'close-circle';
  }

  onClose() {
    this.popOver.dismiss();
    // Lógica para cerrar la tarjeta
  }

  onAction() {
    // Lógica para manejar la acción del botón
  }
  dissmis(){
    this.popOver.dismiss();
  }
}