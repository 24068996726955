import * as i0 from "@angular/core";
export class LocalStorageService {
    constructor() { }
    // Método para guardar datos en el Local Storage
    set(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    }
    // Método para obtener datos del Local Storage
    get(key) {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    }
    // Método para guardar datos con tiempo de expiración
    setWithExpiry(key, value, ttl) {
        const now = new Date().getTime();
        const item = {
            value: value,
            expiry: now + ttl,
        };
        this.set(key, item);
    }
    // Método para obtener datos y verificar si han expirado
    getWithExpiry(key) {
        const itemStr = this.get(key);
        if (!itemStr) {
            return { status: false, message: 'Item not found in cache', data: null };
        }
        const item = itemStr;
        const now = new Date().getTime();
        if (now > item.expiry) {
            localStorage.removeItem(key);
            return { status: false, message: 'Cache expired', data: null };
        }
        return { status: true, message: 'Cache valid', data: item.value };
    }
    remove(item) {
        try {
            localStorage.removeItem(item);
        }
        catch (err) {
            console.error(err);
        }
    }
    removeAll() {
        const id = JSON.parse(localStorage.getItem('Unique_IdDevice'));
        localStorage.clear();
        localStorage.setItem('Unique_IdDevice', JSON.stringify(id));
    }
}
LocalStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalStorageService_Factory() { return new LocalStorageService(); }, token: LocalStorageService, providedIn: "root" });
