import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
// import { PropagandaPage } from './pages/modal/propaganda/propaganda.page';
import { Platform, MenuController, ModalController, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateProvider } from './providers/translate/translate.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { IUserSae, UserAuthenticationService } from './services/user-authentication.service';
import { ConsultasService } from './services/consultas.service';
import { Pages } from './interfaces/pages';
import { SubscriptionGraphqlService } from "./services/subscription-graphql.service";
import { ShowSaldoService } from './services/show-saldo.service';
import { LocalStorageService } from './services/local-storage.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { EstadisticasService } from './services/estadisticas.service';
import { LogErrorsService } from './services/log-errors.service';
import { ClearCacheService } from './services/clear-cache.service';
import { OneSignalService } from './services/one-signal.service';
import { personalizedMenu } from './providers';
import { HelperService } from './services/helper.service';
import { RoutingAppService } from './services/routing-app.service';
import { ConsoleMobileClass } from './services/log/console.mobile.service';
import { SubscriberService } from './core/services/subscriber.service';
import { NetworkStatusService } from './services/network-status.service';
import ismobile from "is-mobile";
import { SeguridadDatos } from './services/bscript.service';
import { UpgradeService, IPlan as IPlanUpgrade } from './services/upgrade.service';
import { QRService } from './services/qr.service';
import { GifCardService } from './services/giftcard.service';

/**
 * Main Wrap App Component with starting methods
 *
 * @export
 * @class AppComponent
 */
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
    VersionApp = environment.version
    ShowMenu: Boolean = true;
    UniqueIdDevice: any
    kioskoApp = environment.kioskoApp;
    UrlAvatar: any = "/assets/img/Globo46x46.png"
    optionsMenu: any;
    sidemenu: boolean = false;
    subscriberUserName: string = '';
    subscriberUserEmail: string = '';

    /**
     * Creates an Array instance with <Pages> interface that receives all menu list.
     *
     * @type {Array<Pages>}
     * @memberof AppComponent
     */
    public get appPages(): Array<Pages> {
        return this.consultService.menuOpcionesReference
    }

    public set appPages(data: Array<Pages>) {
        this.consultService.menuOpcionesReference = data
    }
    
    public user: IUserSae | null = null;
    private propaganda: boolean = false;
    private isAlertOpen: boolean = false;
    public showNavBar: boolean = false;
    /**
     * Creates an instance of AppComponent.
     * @param {Platform} platform
     * @param {SplashScreen} splashScreen
     * @param {StatusBar} statusBar
     * @param {TranslateProvider} translate
     * @param {TranslateService} translateService
     * @memberof AppComponent
     */

    private idleState: string = "NOT_STARTED";
    private countdown?: number = null;
    private lastPing?: Date = null;

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private translate: TranslateProvider,
        private translateService: TranslateService,
        private authentication: UserAuthenticationService,
        public menu: MenuController,
        private consultService: ConsultasService,
        private showSaldoService: ShowSaldoService,
        private _SubscriptionGraphqlService: SubscriptionGraphqlService,
        private _localStorageService: LocalStorageService,
        public modalCtrl: ModalController,
        private idle: Idle,
        private cd: ChangeDetectorRef,
        private alert: AlertController,
        private estadisticas: EstadisticasService,
        private _logs: LogErrorsService,
        private cache: ClearCacheService,
        private onesignal: OneSignalService,
        private helper: HelperService,
        private _routing: RoutingAppService,
        private _log: ConsoleMobileClass,
        private _subscriberService: SubscriberService,
        public networkStatus: NetworkStatusService,
        private _security: SeguridadDatos,
        private qrService: QRService,
        private giftcard: GifCardService
    ) {
        this.initSubscriberListener()
        this._routing.subRouterEvents()//! to start subscription of the router events
        //!Menu local para pruebas
        // this.optionsMenu = OptionMenu


        this.consultService.GetMenu().then(res => {
            // console.log(res)
            //Este metodo es para insertar modulo según la condición del usuario que ingrese a la App
            this.MenuPersonalized();
            //! Menu que viene del API
            //   console.log('this.optionsMenu', this.optionsMenu)
            this.optionsMenu = res;

            // console.log(">>>>>>", this.optionsMenu);


            this.estadisticas.GetAcciones().then((Res: any) => {
                // console.log("Tengo las acciones")
                // console.log(Res)
                this.estadisticas.ListAcciones = Res
            }).catch(err => console.error(err))
            this.estadisticas.GuardarAcciones()

            const user = this.authentication.getUser();


            // console.log(user);
            this.UniqueIdDevice = this._localStorageService.get('Unique_IdDevice')

            
            if (user) {
                this.subscriberUserName = user.cliente || "";
                this.subscriberUserEmail = user.email || "";

                this.consultService.GetAvatar(user.telefono).then((ResAvatar: any) => {
                    if (ResAvatar && ResAvatar.UrlImg) {
                        this.UrlAvatar = ResAvatar.UrlImg
                    } else {
                        this.UrlAvatar = "/assets/img/Globo46x46.png"
                    }
                }).catch((error: any) => {
                    console.error(error)
                    this.UrlAvatar = "/assets/img/Globo46x46.png"
                })
                this.giftcard.getCardBalance().then((response: any)=>{
                    if(response){
                     if(!this.optionsMenu.some(option=>option.url.includes("club-fibex/gift-card-home")))   this.optionsMenu.splice(3,0,this.giftcard.giftCardObjectRout);
                    }
                })
            } else { this.UrlAvatar = "/assets/img/Globo46x46.png" }

            if (environment.kioskoApp) {
                this.initTimer();
                this.consultService.menuOpcionesReference = this.appPages = this.optionsMenu.filter(option => (
                    //option.url !== '/club-fibex/home' &&
                    option.url !== '/planes' &&
                    option.url !== '/booking-list' &&
                    option.url !== '' &&
                    option.url !== '/fibex-remote-control' &&
                    option.url !== '/rentcar' &&
                    option.url !== '/factura' &&
                    option.url !== '/referidos' &&
                    option.url !== '/speedtest' &&
                    option.url !== '/recommend-and-win' &&
                    option.url !== '/wifi' &&
                    option.url !== '/security'))

                // console.log(this.appPages)
            } else {
                this.consultService.menuOpcionesReference = this.appPages = this.optionsMenu;

            }

            // console.log(this.optionsMenu)
            // console.log(this.appPages)

            this.helper.listenYourVariable().subscribe(res => {
                this.showNavBar = !this.showNavBar
            })


            this.initializeApp();
            this.getUserData();
            /* this.getInfo.getIpFromUser()
              .subscribe(res => this._localStorageService.set('ipUser', res)); */
            //this.guia.loadGuia();

            this.GenerateIdDevice()

            // console.log("Usuario desde el constructor",this.user);

            // const versionPrueba = 'v2.9.22'
            this.consultService.VersionControl(this.user && this.user.cedula, this.VersionApp)
                .then((data: any) => {
                    // {
                    //   "Tipo":"App",
                    //   "url":""
                    // }
                    if (data) {
                        if (data.url == 'https://app.fibextelecom.net/') {
                            //console.log("La aplicacion necesita actualizarse");
                            this.mostrarAlerta('web');
                            this.cache.clear();
                            this.reset();
                        }
                    }
                }
                ).catch((error: any) => { console.error("this.consultService.VersionControl", error) });
            this.consultService.ChangeEmail.subscribe((DataUser: IUserSae) => {
                this.user = DataUser;
            })

        });

        // const url = new URL(location.href);

        // // Validar si se trata de una aplicación movíl
        // if (!(ismobile() || !environment.production || url.searchParams.get("web_authorization") == "true")) {
        //     location.href = "https://app.fibextelecom.net/download";
        // }

        // instance.interceptors.response.use((value) => {
        //   console.log(value);
        // }, (err) => {
        //   console.error(err)
        //   if(axios.isAxiosError(err)) {
        //     if(err.response && err.response.data && typeof err.response.data.message === "string") {
        //       console.log(this._security.Descryp(err.response.data.message));
        //     }
        //   }
        // })
    }

    //

    async mostrarAlerta(type: string) {
        if (type === 'web') {
            const alert = await this.alert.create({
                header: 'Alerta',
                message: 'Su versión es antigua, por lo tanto necesita actualizarse',
                cssClass: 'secondary cust',
                buttons: [
                    {
                        text: 'Aceptar',
                        handler: () => {
                            //this.logout();
                        }
                    }
                ]
            });
            await alert.present();
        } else {
            const alert = await this.alert.create({
                header: 'Alerta',
                message: 'Su versión es antigua, por lo tanto necesita actualizarse',
                buttons: [
                    {
                        text: 'Aceptar',
                        handler: () => {
                            window.open('https://play.google.com/store/apps/details?id=com.jermsoft.fibexapp.v2', '_system');
                            //this.logout();
                        }
                    }
                ]
            });
            await alert.present();
        }
    }

    async MenuPersonalized() {
        this.user = this.authentication.getUser();
        //Valido si cumple con la condición para el wifi

        if(this.user) {
            //Equipos del usuario
            const ResEquipos = await this.consultService.InfoEquiposClientes(this.user.cedula);
            const equipos = ResEquipos.length;
    
            if (equipos > 0) {
                //   console.log("Me subscribi");
                this.consultService.MenuPersonalized.subscribe((data: any) => {
                    //console.log(data)
                    if (data) {
                        //console.log("Me llego al data")
                        // console.log('menu')
                        // console.table(this.slideItems)
                        const found = this.appPages.findIndex(route => route.url === '/wifi')
                        if (found === -1) this.appPages.unshift(personalizedMenu[0]);
                    } else {
                        //console.log("No llego al data")
                        this.consultService.MenuPersonalized.unsubscribe();
                    }
                });
                // this.consultService.DisEventMenu(this.user);
            } else {
                console.log(this.consultService.MenuPersonalized);
                this.consultService.menuOpcionesReference = this.appPages = this.appPages.filter(route => route.url !== '/mi-equipo');
    
            }
        }
    }
    /**
     * Method that starts all Cordova and Factories
     *
     * @memberof AppComponent
     */
    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            setTimeout(() => {
                this.splashScreen.hide();
            }, 1000);
            // Set language of the app.
            this.translateService.setDefaultLang(environment.language);
            this.translateService.use(environment.language);
            this.translateService.getTranslation(environment.language).subscribe(translations => {
                this.translate.setTranslations(translations);
            });
        }).catch((error: any) => {
            // Set language of the app.
            this.translateService.setDefaultLang(environment.language);
            this.translateService.use(environment.language);
            this.translateService.getTranslation(environment.language).subscribe(translations => {
                this.translate.setTranslations(translations);
            });
            this._logs.insertarError(error.message)
        });
    }

    GenerateIdDevice() {
        try {

            if (!this.UniqueIdDevice) {
                const id: string = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString(16);
                this._localStorageService.set('Unique_IdDevice', id)
            }


        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Navigate to Edit Profile Page
     *
     * @memberof AppComponent
     */

    goToEditProgile() {
        this._routing.ChangeRuta('edit-profile');
        this.closeMenu();
    }
    /**
     * Logout Method
     *
     * @memberof AppComponent
     */

    ngOnInit(): void {
        //Con esto siempre limpio cache al cliente ya que no cierran sesión
        //console.log('pasando por el onInit')
        this.cache.clear();
        /*this.consultService.VersionControl(this.user && this.user.identidad, this.VersionApp)
        .then((data: any)=>{
          // {
          //   "Tipo":"App",
          //   "url":""
          // }
          if(data){
            if (data.url == 'https://app.fibextelecom.net/'){
              console.log("La aplicacion necesita actualizarse");
              this.cache.clear();
              this.reset();
            }
         }
        }
        ).catch((error:any)=>{console.log("this.consultService.VersionControl",error)});*/

        if (environment.kioskoApp) {
            this.reset();
        }

        if (this.user) {

            this.consultService.ServerDisponibles(this.user.cedula).then((ResServer: any) => {
                if (Object.keys(ResServer).length > 0) {

                    environment.ApiGraphQl = ResServer.ApiGraphQl
                    environment.UrlSubscriptions = ResServer.UrlSubscriptions
                    environment.apiChatBot = ResServer.apiChatBot
                    environment.apiMaps = ResServer.apiMaps
                    environment.apiPhP = ResServer.apiPhP

                    if (ResServer.OnSuscription) {
                        this._SubscriptionGraphqlService.initSubscription()
                    }

                }
            }).catch((error: any) => {
                this._logs.insertarError(error.message);
            })



            /* this.consultService.VersionControl(this.user.identidad, this.VersionApp).then((res: any) => {
              if (res.url) {
                this.ShowMenu = false
                this.navCtrl.navigateForward('NewUpdate');
              }
            }) */
        }

        this._SubscriptionGraphqlService.OpSelect.subscribe((op: any) => {
            switch (op.option) {
                case "op12-1":
                    this.menu.open()
                    break;
                case "op12-2":
                    this.menu.close()
                    break;

            }

        })

    }

    async closeMenu() {
        if (await this.menu.isOpen()) {
            await this.menu.close();
            await this.menu.enable(false);
        }
    }

    getUserData() {
        this.user = this.authentication.getUser();
        this.authentication.userState.subscribe((userData: IUserSae) => this.user = userData);
    }

    async logout() {
      this.showNavBar = false;
      let Wifi = this.appPages.findIndex((Menu) => Menu.title == "Clave Wifi")
      //Wifi
      if (Wifi != -1) this.consultService.menuOpcionesReference = this.appPages = await this.consultService.GetMenu();
      this.qrService.logOutToken();
      this.consultService.Logout();      
    }

    showSaldo(url: string) {
        this.showSaldoService.setMenu(url);
    }

    initTimer() {
        const user = this.authentication.getUser();
        if (user) {
            this.idle.setIdle(60); // how long can they be inactive before considered idle, in seconds
            this.idle.setTimeout(30); // how long can they be idle before considered timed out, in seconds
        } else {
            this.idle.setIdle(30);
            this.idle.setTimeout(30);
        }
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

        // do something when the user becomes idle
        this.idle.onIdleStart.subscribe(() => {
            this.idleState = "IDLE";
            const user = this.authentication.getUser();
            if (user) {
                this.createAlert("La sesión finalizará en: 30s");
                this.isAlertOpen = true;
            } else {
                // this.openPropaganda();
                this.propaganda = true;
            }

        });
        // do something when the user is no longer idle
        this.idle.onIdleEnd.subscribe(() => {
            const user = this.authentication.getUser();
            if (user) {
                if (this.isAlertOpen) {
                    this.isAlertOpen = false;
                    this.alert.dismiss();
                }
                this.idle.setIdle(60); // how long can they be inactive before considered idle, in seconds
                this.idle.setTimeout(30);
            } else {
                if (this.propaganda) {
                    this.modalCtrl.dismiss();
                    this.propaganda = false;

                }
                this.idle.setIdle(60); // how long can they be inactive before considered idle, in seconds
                this.idle.setTimeout(120);
            }

            this.idleState = "NOT_IDLE";
            this.countdown = null;
            this.cd.detectChanges(); // how do i avoid this kludge?

        });
        // do something when the user has timed out
        this.idle.onTimeout.subscribe(() => {
            const user = this.authentication.getUser();
            if (user) {
                if (this.isAlertOpen) {
                    this.alert.dismiss();
                    this.isAlertOpen = false;
                    this.logout();
                }

                this.idle.setIdle(60);
                this.idle.setTimeout(30);
            } else {
                if (this.propaganda) {
                    this.propaganda = false;
                    this.modalCtrl.dismiss();
                }
                this.idle.setIdle(60);
                this.idle.setTimeout(120);
            }
            this.idleState = "TIMED_OUT"
            this.reset();
        });
        // do something as the timeout countdown does its thing
        this.idle.onTimeoutWarning.subscribe(seconds => {
            this.countdown = seconds
        });

        // set keepalive parameters, omit if not using keepalive
        //this.keepalive.interval(15); // will ping at this interval while not idle, in seconds
        //this.keepalive.onPing.subscribe(() => this.lastPing = new Date()); 
    }


    reset() {
        // we'll call this method when we want to start/reset the idle process
        // reset any component state and be sure to call idle.watch()
        this.idle.watch();
        this.idleState = "NOT_IDLE";
        this.countdown = null;
        this.lastPing = null;
    }

    async createAlert(header: string) {
        const alert = await this.alert.create({
            header,
            buttons: [
                {
                    text: 'Cerrar',
                    role: 'Cancelar',
                    cssClass: 'secondary',
                }
            ]
        });
        alert.present()
    }


    /*async openPropaganda() {
        const modal = await this.modalCtrl.create({
            component: PropagandaPage,
            cssClass: 'small-modal'
        }
        );
        await modal.present();
    }*/

    async navigate(url: string) {
        if (url === '') window.open('https://play.google.com/store/apps/details?id=com.tvappwatch.fibextelecom', '_system', 'location=yes')
        else this._routing.ChangeRuta(url)
    }

    closeNav() {
        this.showNavBar = false;
    }

    initSubscriberListener() {
        this._subscriberService.currentSubscriber$.subscribe(res => {

            console.log("UPDATE MENU PERFIL::::", this.authentication.getUser());
            this.updateSubscriberInformation();
        })
    }

    updateSubscriberInformation() {
        // console.log('LISTENING')
        const user = this.authentication.getUser();

        if(user) {
            this.user = user; 
            this.subscriberUserName = this.user.cliente;
            this.subscriberUserEmail = this.user.email;
        }
    }
}
