
<ion-header>
    <ion-toolbar class="ion_gradient_cfbg">
        <ion-button slot="start" fill="clear" (click)="onBackNavigation()">
            <ion-icon name="chevron-back-outline" color="light"></ion-icon> 
        </ion-button>
        <ion-title style="text-align: center;"  color="light">Filtrar</ion-title>
        
        <ion-button slot="end"  mode="ios" (click)="onNavigation()" class="btn-sup-ready" >
            Listo
        </ion-button>
    </ion-toolbar>
</ion-header> 

<ion-content class="ion-padding" style="overflow-y: auto;">
    <!--ACORDION-->
    
  <div class="container-questions">
    <div class="item-question" #faqElement [attr.data-faq]="1" [id]="1"
     >
      <button class="question-container padding_15"  (click)="itemAccordion( faqElement,1)">
        <p class="question">
            Ciudades
            <!-- <ion-icon slot="start" name="close"></ion-icon> -->
            <ion-icon name="chevron-down-outline"></ion-icon>
          </p>
      </button>
        <div class="anwser">
            <app-modal-location  class="w-100" [isModalMode]="false" [selectedCities]="selectedCities"
            (franqSelectedEvent)="onFranqSelected($event)" (close)="handleShowFranqModal(false)" ></app-modal-location>
                
        </div>
    </div>
  </div>
  <div class="container-questions">
    <div class="item-question" #faqElement2 [attr.data-faq]="2" [id]="2"
     >
      <button class="question-container padding_15"  (click)="itemAccordion( faqElement2,2)">
        <p class="question">
            Categorias
            <!-- <ion-icon slot="start" name="close"></ion-icon> -->
            <ion-icon name="chevron-down-outline"></ion-icon>
          </p>
      </button>
        <div class="anwser">
            <div class="group">
                <div class="flex_div">
                    <div class="div_item" *ngFor="let item of categories.slice(1)">
                        <img *ngIf="hasSelectedCat(item)" src="../../assets/img/auxiliar/selection.png" class="selection-icon">
        
                        <button class="categorie {{hasSelectedCat(item) ? item.color : 'white'}}" (click)="addActiveCat(item)">
                            <img *ngIf="!hasSelectedCat(item)" [src]="item.img">
                            <img *ngIf="hasSelectedCat(item)" [src]="item.imgWhite">
                        </button>
                        <ion-label>{{item.nombre}}</ion-label>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
  </div>
  <div *ngIf="!activeOptionTab" class="container-questions">
    <div class="item-question" #faqElement3 [attr.data-faq]="3" [id]="3"
     >
      <button class="question-container padding_15"  (click)="itemAccordion( faqElement3,3)">
        <p class="question">
            Rango del precio
            <!-- <ion-icon slot="start" name="close"></ion-icon> -->
            <ion-icon name="chevron-down-outline"></ion-icon>
          </p>
      </button>
        <div class="anwser">
            <div class="ratting">
                <div class="range-container">
                    <span class="row_container"> 
                        <div class="column">
                            <ion-label>
                                Desde
                            </ion-label>
                            <input (change)="onChangeMinRAnge($event)" type="text" [value]="minRange" >
                        </div>
                        <div class="column">
                            <ion-label>
                                Hasta
                            </ion-label>
                            <input (change)="onChangemaxRange($event)"  type="text" [value]="maxRange" >
                        </div>
                    </span>
        
                    <!-- <input type="range" min="0" max="1000" (change)="trigger($event)" class="slider" > -->
                    <ion-range class="ion-range-bar" [dualKnobs]="true" [snaps]="true" [ticks]="true" max="1000"
                        [value]="{ lower: toNumber(minRange), upper: toNumber(maxRange) }" (ionChange)="trigger($event)"></ion-range>
        
                    <!-- <div class="div_item" [class.active]="price === 'all'" (click)="price = 'all'">
                        <ion-label>Todos</ion-label>
                    </div>
                    <div class="div_item" [class.active]="price == itemPrice" *ngFor="let itemPrice of rangePrices" (click)="price = itemPrice">
                        <ion-label>{{itemPrice}}</ion-label>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
  </div>
  <div *ngIf="!activeOptionTab" class="container-questions">
    <div class="item-question" #faqElement4 [attr.data-faq]="4" [id]="4"
     >
      <button class="question-container padding_15"  (click)="itemAccordion( faqElement4,4)">
        <p class="question">
            Descuento por
            <!-- <ion-icon slot="start" name="close"></ion-icon> -->
            <ion-icon name="chevron-down-outline"></ion-icon>
          </p>
      </button>
        <div class="anwser">
            <div class="discount-type">
                <span  class="tabs-container">
                    <button class="discount" [class.tabActive]="activeTabMoneyType === COUPON_MONEY_TYPES.DISCOUNT" (click)="transformCoupMoney(COUPON_MONEY_TYPES.DISCOUNT)">%</button>
                    <button class="dollar" [class.tabActive]="activeTabMoneyType === COUPON_MONEY_TYPES.DOLLAR" (click)="transformCoupMoney(COUPON_MONEY_TYPES.DOLLAR)">$</button>
                    <span class="glider"></span>
                  </span>
            </div>
        </div>
    </div>
  </div>
  <div  *ngIf="!activeOptionTab"  class="container-questions">
    <div class="item-question" #faqElement5 [attr.data-faq]="5" [id]="5"
     >
      <button class="question-container padding_15"  (click)="itemAccordion( faqElement5,5)">
        <p class="question">
            Aliados
            <!-- <ion-icon slot="start" name="close"></ion-icon> -->
            <ion-icon name="chevron-down-outline"></ion-icon>
          </p>
      </button>
        <div class="anwser">
            <div class="location-filter">
                <h6 class="title_lbl">Filtrar por  aliado:</h6>
        
                <app-modal-companies [isModalMode]="false"  [selectedCompanies]="selectedCompanies" [selectedCities]="activeOptionTab ? selectedCities : []"
                (companySelectedEvent)="onCompaniesSelected($event)" 
                ></app-modal-companies>
            </div>
        </div>
    </div>
  </div>
  <div class="container-questions">
    <div class="item-question" #faqElement6 [attr.data-faq]="6" [id]="6"
     >
      <button class="question-container padding_15"  (click)="itemAccordion( faqElement6,6)">
        <p class="question"> 
            Vista
            <!-- <ion-icon slot="start" name="close"></ion-icon> -->
            <ion-icon name="chevron-down-outline"></ion-icon>
          </p>
      </button>
        <div class="anwser">
            <div class="discount-type">
                <span  class="tabs-container">
                    <button class="discount" [class.tabActive]="activeOptionTab" (click)="setOPtionTab(true)">Aliado</button>
                    <button class="dollar" [class.tabActive]="!activeOptionTab" (click)="setOPtionTab(false)">Promo</button>
                    <span class="glider"></span>
                </span>
            </div>
        </div>
    </div>
  </div>
  <div class="container-questions">
    <div class="item-question" #faqElement7 [attr.data-faq]="7" [id]="7"
     >
      <button class="question-container padding_15"  (click)="itemAccordion( faqElement7,7)">
        <p class="question"> 
            ¿Acepta la tarjeta de regalo?
            <!-- <ion-icon slot="start" name="close"></ion-icon> -->
            <ion-icon name="chevron-down-outline"></ion-icon>
          </p>
      </button>
        <div class="anwser">
            <div class="discount-type">
                <span  class="tabs-container">
                    <button class="discount" [class.tabActive]="isModeScratchWind" (click)="setOPtionSAW(true)">Si</button>
                    <button class="dollar" [class.tabActive]="!isModeScratchWind" (click)="setOPtionSAW(false)">No</button>
                    <span class="glider"></span>
                </span>
            </div>
        </div>
    </div>
  </div>
    <!-- <div class="location-filter">
        <h6 class="title_lbl margin-b-0">Ciudades</h6>
        <app-modal-location class="w-100" [isModalMode]="false" [selectedCities]="selectedCities"
        (franqSelectedEvent)="onFranqSelected($event)" (close)="handleShowFranqModal(false)" ></app-modal-location>
            <app-modal-companies *ngIf="showCompanyModal" [selectedCities]="selectedCompanies"
            (companySelectedEvent)="onCompaniesSelected($event)" 
            ></app-modal-companies>
        <div class="franq-selected-list" *ngIf="selectedCities?.length">
            <ul>
                <li *ngFor="let city of selectedCities">
                    {{ allFranqIndexed[city]?.Nombre | titlecase }}
                </li>
            </ul>
        </div>
    </div> -->
    <!-- <div class="group">
        <ion-title class="title_lbl">Categorías</ion-title> -->

        <!-- <div class="flex_div">
            <div class="div_item" *ngFor="let item of categories.slice(1)">
                <img *ngIf="hasSelectedCat(item)" src="../../assets/img/auxiliar/selection.png" class="selection-icon">

                <button class="categorie {{hasSelectedCat(item) ? item.color : 'white'}}" (click)="addActiveCat(item)">
                    <img *ngIf="!hasSelectedCat(item)" [src]="item.img">
                    <img *ngIf="hasSelectedCat(item)" [src]="item.imgWhite">
                </button>
                <ion-label>{{item.nombre}}</ion-label>
            </div>
        </div> -->
    <!-- </div> -->

    <!-- <div class="ratting">
        <ion-title class="title_lbl">Clasificación</ion-title>

        <div class="flex_div">
            <div class="div_item" *ngFor="let item of stars">
                <img *ngIf="hasSelectedStar(item)" src="../../assets/img/auxiliar/selection.png" class="selection-icon">
                <button class="stars" [class.active]="hasSelectedStar(item)" (click)="addActiveStar(item)">
                    <img *ngIf="!hasSelectedStar(item)" [src]="item.url">
                    <img *ngIf="hasSelectedStar(item)" [src]="item.urlBlanco">
                </button>
            </div>
        </div>
    </div> -->

    <!-- <div class="ratting"> -->
        <!-- <ion-title class="title_lbl">Rango del precio</ion-title>
        <div class="range-container">
            <span class="row_container"> 
                <div class="column">
                    <ion-label>
                        Desde
                    </ion-label>
                    <input type="text" [value]="minRange" readonly [disabled]="true">
                </div>
                <div class="column">
                    <ion-label>
                        Hasta
                    </ion-label>
                    <input type="text" [value]="maxRange" readonly [disabled]="true">
                </div>
            </span>

            <!-- <input type="range" min="0" max="1000" (change)="trigger($event)" class="slider" > -->
            <!-- <ion-range class="ion-range-bar" [dualKnobs]="true" [snaps]="true" [ticks]="true"
                [value]="{ lower: toNumber(minRange), upper: toNumber(maxRange) }" (ionChange)="trigger($event)"></ion-range> -->

            <!-- <div class="div_item" [class.active]="price === 'all'" (click)="price = 'all'">
                <ion-label>Todos</ion-label>
            </div>
            <div class="div_item" [class.active]="price == itemPrice" *ngFor="let itemPrice of rangePrices" (click)="price = itemPrice">
                <ion-label>{{itemPrice}}</ion-label>
            </div> -->
        <!-- </div>  -->
    <!-- </div> -->

    <!-- <div class="discount-type">
        <h6 class="title_lbl">Tipo de descuento</h6>
        <span  class="tabs-container">
            <button class="discount" [class.tabActive]="activeTabMoneyType === COUPON_MONEY_TYPES.DISCOUNT" (click)="transformCoupMoney(COUPON_MONEY_TYPES.DISCOUNT)">%</button>
            <button class="dollar" [class.tabActive]="activeTabMoneyType === COUPON_MONEY_TYPES.DOLLAR" (click)="transformCoupMoney(COUPON_MONEY_TYPES.DOLLAR)">$</button>
            <span class="glider"></span>
          </span>
    </div> -->

    <!-- ! Comentado momentaneamente -->
   
<!--   
    <div class="location-filter">
        <h6 class="title_lbl">Filtrar por  aliado:</h6>

        <ion-button mode="ios" class="ion-btn-green-clear" (click)="handleShowCompanyModal(true)">
            Seleccionar Aliado
        </ion-button>

        <div class="franq-selected-list" *ngIf="selectedCompanies?.length">
            <ul>
                <li *ngFor="let city of selectedCompanies">
                    {{ allCompaniesIndexed[city]?.name | titlecase }}
                </li>
            </ul>
        </div>
    </div> -->

</ion-content>


<ion-footer>
    <!-- <ion-toolbar>
        <ion-button (click)="onNavigation()" expand="block" mode="ios" class="block_btn ion_gradient_cf">
            Aplicar Filtros
        </ion-button>
    </ion-toolbar> -->
    <div class="footer-btns" [ngClass]="{'full_width_btn': !showResetFilter}">
        <ion-button (click)="onNavigation()" mode="ios" class="blue-new">
            Listo
        </ion-button>

        <ion-button (click)="resetFilter()" mode="ios" class="block_btn warning">
            <ion-icon name="refresh"></ion-icon>
        </ion-button>

    </div>
</ion-footer> 