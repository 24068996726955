
import { FranquiciaInterface } from '../../../../interfaces/getClubFibex.interface';
import { LettersService } from '../../../../services/letters.service';
import { ClubFibexFeedingService } from '../../../../services/club-fibex-feeding.service';
import { Component, Input, OnInit, Output, EventEmitter, ElementRef, ViewChildren, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { log } from 'console';

@Component({
  selector: 'app-modal-location',
  templateUrl: './modal-location.component.html',
  styleUrls: ['./modal-location.component.scss'],
})
export class ModalLocationComponent implements OnInit, OnChanges {

  @Input() styleClass: string = '';
  @Input() isModalMode: boolean =  true;
  @Input() selectedCities: string[] = [];
  @Output() close: EventEmitter<void> = new EventEmitter<void>()
  @Output() franqSelectedEvent: EventEmitter<string[]> = new EventEmitter<string[]>()
  
  public franquicias: FranquiciaInterface[] = [];
  public filteredItems: FranquiciaInterface[] = [];
  public FranqSelectedId: string[] = [];
  public loadingData: boolean = false;
  public areLoadingElementes: boolean = false;
  public allElementsLoades:boolean = false;
  public limit = 15;
  public offset = 1;
  @ViewChild('scrollElement',{static:false}) private scrollableDiv: ElementRef;
  constructor(
    public letterService: LettersService,
    private clubService: ClubFibexFeedingService,
  ) {
  }

  async ngOnInit() {
    console.log(this.selectedCities);
    await this.initData()
  }
  ngOnChanges(changes: SimpleChanges): void {
    
    if(changes['selectedCities'].currentValue.length>0){
      console.log(this.selectedCities);
      if(this.FranqSelectedId.length < 1) this.FranqSelectedId = [...this.selectedCities];
    }
    else if(changes['selectedCities'].currentValue.length<1){
      this.FranqSelectedId = [];
    }
  }
  private async initData(): Promise<void> {
    this.loadingData=true;
    console.log('selectedFranq', this.selectedCities)
    if(this.selectedCities.length>0){
      for (const franqId of this.selectedCities) {
        this.FranqSelectedId.push(franqId);
      }
    }
  
    console.log('franq id => ', this.FranqSelectedId)
    this.franquicias = await this.clubService.getSucursales();
    this.franquicias.map(franq => franq.Nombre = franq.Nombre.replace('FIBEX ', ''));
    console.log('franquicias',this.franquicias);
    this.filteredItems = [...this.franquicias];
    this.loadingData=false;
  }

  trackItems(index: number, item: FranquiciaInterface) {
    return item.idEnSAE;
  }

  cancel() {
    this.close.emit()
  }

  confirmChanges() {
    console.log(this.FranqSelectedId);
    this.franqSelectedEvent.emit(this.FranqSelectedId)
    /*
     !En caso que se requiera guardar las franquicias en el localStorage 
     this.localStorageService.set('user_franq', this.FranqSelectedId || [])
    */
  }

  searchbarInput(ev) {
    this.filterList(ev.target.value);
  }
  moveBackScroll(){
    this.scrollableDiv.nativeElement.scrollLeft -= 20;
  }
  moveForwardScroll(){
    this.scrollableDiv.nativeElement.scrollLeft += 20;
  }
  /**
   * Update the rendered view with
   * the provided search query. If no
   * query is provided, all data
   * will be rendered.
   */
  filterList(searchQuery: string | undefined) {
    /**
     * If no search query is defined,
     * return all options.
     */
    if (searchQuery === undefined) {
      this.filteredItems = [...this.franquicias];
    } else {
      /**
       * Otherwise, normalize the search
       * query and check to see which items
       * contain the search query as a substring.
       */
      const normalizedQuery = searchQuery.toLowerCase();
      this.filteredItems = this.franquicias.filter((item) => {
        return item.Nombre.toLowerCase().includes(normalizedQuery);
      });
    }
  }

  isChecked(value: string) {
    return this.FranqSelectedId.find((item) => item === value);
  }

  checkboxChange(ev) {
 console.log("SLEECCIONADO: "+ev);
    const checked: number = ev.target.checked
    const value = ev.target.value;
    console.log(value);
    
    if (checked) {
      this.FranqSelectedId = [...this.FranqSelectedId, value];
    
    } else {
      this.FranqSelectedId = this.FranqSelectedId.filter((franquicia) => franquicia !== value);
    }
    if(!this.isModalMode) this.confirmChanges();
  }

  public toString = (object: unknown): string => {
    return JSON.stringify(object)
  }
  // public infiniteScroll = async (event:Event) => {
  //   try {
  //   const scrollEvent = event.target as HTMLElement;
  //   const scrollPosition = scrollEvent.scrollTop;
  //   const scrollHeight = scrollEvent.scrollHeight; 
  //   const offsetHeight = scrollEvent.offsetHeight;
  //   if (scrollPosition >= scrollHeight - offsetHeight ) {
  //       console.log("IDDDDDDDDD")
  //   const response: any = await this.updateDataPaginated();
  //   if(response==true){
  //     const newPosition = Math.round(scrollPosition - (offsetHeight * 0.2));
  //     // Mueve la página a la nueva posición
  //     scrollEvent.scrollTo({ top: newPosition, behavior: 'smooth' });
  //    }
  //   }

  //   } catch (error) {
  //     console.error(error);
  //   }
    

  // }
  // public async updateDataPaginated(){
  //   try {
  //     if(!this.allElementsLoades){
  //       this.areLoadingElementes= true;
  //     //  alert("Restulados: "+this.isAllProductsLoaded+this.isloadingProducts)
  //       this.offset++;
  //       this.loadingData=true;
  //       const getMoreData: any[] = await this.clubService.GetCompaniesSelect(this.limit,this.offset);
  //       await new Promise(resolve => setTimeout(resolve, 500));
  //       this.loadingData=false;
  //       if(getMoreData.length>0){
  //        this.franquicias.push(...getMoreData);
  //        this.filteredItems = [...this.franquicias];
  //        console.log(this.filteredItems);
         
  //        console.log(this.franquicias);
         
  //        //  alert(this.allProducts.length);
        
  //       }
  //       this.allElementsLoades = (getMoreData.length<this.limit);
  //       this.areLoadingElementes = false;
  //     }
  //     else return Promise.resolve(false);
  //   return  Promise.resolve(true);
  //   } catch (error) {
  //     console.log(error);
  //     return Promise.reject(error);
  //   }

  // }
}
