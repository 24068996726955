import * as tslib_1 from "tslib";
import { LocalStorageService } from './local-storage.service';
import { dbFullDataBase } from '../lib/dbfullv2';
import { environment } from '../../environments/environment';
import axios from 'axios';
import { UserAuthenticationService } from './user-authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "./local-storage.service";
import * as i2 from "./user-authentication.service";
export class GifCardService {
    constructor(localStorage, userAuth) {
        this.localStorage = localStorage;
        this.userAuth = userAuth;
        this.dbFull = new dbFullDataBase('thomas_tarjeta_regalos');
        this.urlAPI = environment.apiTest;
        this.giftCardObjectRout = {
            "title": "Tarjeta Regalo",
            "url": "club-fibex/gift-card-home",
            "direct": "forward",
            "icon": "",
            "show": true,
            "showHome": true,
            "showMenu": true,
            // "image": 'assets/img/icons/Iconos2.png'
            "img": "assets/icons/gift_card.png",
            "image": "assets/icons/gift_card.png",
        };
        this.cupones_history = [];
    }
    getCuponesHistory() {
        return this.cupones_history;
    }
    setCuponesHistory(cupones) {
        this.cupones_history = cupones;
    }
    fetchCuponesHistory() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.cupones_history.length === 0) {
                const limit = 30;
                const offset = 0;
                try {
                    const cupones = yield this.getHistoryGiftCardUser(limit, offset);
                    this.setCuponesHistory(cupones);
                    return cupones;
                }
                catch (error) {
                    console.error('Error fetching cupones history:', error);
                    throw error;
                }
            }
            else {
                return this.getCuponesHistory();
            }
        });
    }
    setGiftCardInLocalStorage(giftCard) {
        const threeMinutes = 30 * 1000; // 3 minutos en milisegundos
        this.localStorage.setWithExpiry('saw', giftCard, threeMinutes);
    }
    fetchCardBalance() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = this.localStorage.getWithExpiry('saw');
            console.log(result, "result");
            if (result.data !== undefined && result.status) {
                let data = result.data;
                if (Array.isArray(data))
                    return result.data[0];
                return result.data;
            }
            else {
                const response = yield this.getCardBalance();
                return response;
            }
        });
    }
    getCardBalance() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const apiEndpointExchange = environment.endpointConsult;
            try {
                const responseExchange = yield axios.get(`${this.urlAPI}/${apiEndpointExchange}?idAbonado=${this.userAuth.getUser().nro_contrato}`);
                console.log(responseExchange);
                const response = responseExchange.data;
                if (response.hasOwnProperty("error"))
                    throw response;
                // Guardar el balance en el Local Storage
                if (response.status)
                    this.setGiftCardInLocalStorage(response.tarjetas);
                return response.tarjetas;
            }
            catch (error) {
                console.error('Error in exchangeGiftcard:', error);
                // Lanza el error para que pueda ser manejado en el componente
                throw error.response ? error.response.data : error;
            }
        });
    }
    exchangeGiftcard(code) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const apiEndpointExchange = environment.endpointExchange;
            try {
                const dataResponse = { codigo: code, id_abonado: this.userAuth.getUser().nro_contrato };
                console.log(dataResponse);
                const responseExchange = yield axios.post(`${this.urlAPI}/${apiEndpointExchange}`, dataResponse);
                console.log(responseExchange);
                const response = responseExchange.data;
                // Guardar el valor de 'giftcard' en el Local Storage
                if (response.giftcard) {
                    this.setGiftCardInLocalStorage(response.giftcard);
                }
                if (response.hasOwnProperty("error"))
                    throw response;
                return response;
            }
            catch (error) {
                console.error('Error in exchangeGiftcard:', error);
                // Lanza el error para que pueda ser manejado en el componente
                throw error.response ? error.response.data : error;
            }
        });
    }
    getHistoryGiftCardUser(limit, offset) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const endpoint = environment.endpointHistory;
            try {
                const responseExchange = yield axios.get(`${this.urlAPI}/${endpoint}?abonado=${this.userAuth.getUser().nro_contrato}&lim=${limit}&off=${offset}`);
                console.log(responseExchange);
                const response = responseExchange.data;
                if (response.hasOwnProperty("error"))
                    throw response;
                else
                    return response.cupones;
            }
            catch (error) {
                console.error('Error in exchangeGiftcard:', error);
                // Lanza el error para que pueda ser manejado en el componente
                throw error.response ? error.response.data : error;
            }
        });
    }
}
GifCardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GifCardService_Factory() { return new GifCardService(i0.ɵɵinject(i1.LocalStorageService), i0.ɵɵinject(i2.UserAuthenticationService)); }, token: GifCardService, providedIn: "root" });
